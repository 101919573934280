@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.register_sectiondiv__1sHXp {
  position: relative;
  max-height: 100vh;
  width: 100%;
  background: #121321;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  perspective: 1000px;
  margin-top: 74px;
}
.register_sectiondiv__1sHXp::before {
  content: "";
  position: absolute;
  height: 240px;
  width: 240px;
  border-radius: 50%;
  transform: translate(-150px, -100px);
  background: linear-gradient(90deg, #9c27b0, #f3f5f5);
}
.register_sectiondiv__1sHXp::after {
  content: "";
  position: absolute;
  height: 240px;
  width: 240px;
  border-radius: 50%;
  transform: translate(150px, 100px);
  background: linear-gradient(90deg, #9c27b0, #f3f5f5);
}
.register_containerCredit__3A7Ul {
  position: relative;
  height: 225px;
  width: 375px;
  z-index: 100;
  transition: 0.6s;
  transform-style: preserve-3d;
}
.register_containerCredit__3A7Ul:hover {
  transform: rotateY(180deg);
}
.register_containerCredit__3A7Ul .register_card__3AIxr {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 25px;
  border-radius: 25px;
  -webkit-backdrop-filter: blur(25px);
          backdrop-filter: blur(25px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backface-visibility: hidden; /* Ensure backface-visibility is set */
  transform-style: preserve-3d;
}
.register_frontface__QT8tt {
  backface-visibility: hidden;
  background: transparent;
}
.register_frontface__QT8tt header,
.register_frontface__QT8tt .register_logo__b7zUz {
  display: flex;
  align-items: center;
}
.register_frontface__QT8tt header {
  justify-content: space-between;
}
.register_frontface__QT8tt .register_logo__b7zUz img {
  width: 48px;
  margin-right: 10px;
}
.register_titrecredit__2uLRD {
  font-size: 16px;
  font-weight: 400;
}
.register_frontface__QT8tt .register_chip__3y8X5 {
  width: 50px;
}
.register_frontface__QT8tt .register_carddetails__2kwjm {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.register_CardNumber__1Jhls {
  font-size: 10px;
  font-weight: 400;
}
.register_titrecredit__2uLRD.register_number__40zF0 {
  font-size: 18px;
  letter-spacing: 1px;
}
.register_titrecredit__2uLRD.register_name__2GS9C {
  margin-top: 20px;
}
.register_card__3AIxr.register_backface__3rnXU {
  border: none;
  padding: 15px 25px 25px 25px;
  transform: rotateY(180deg);
  backface-visibility: hidden; /* Ensure backface-visibility is set */
}
.register_card__3AIxr.register_backface__3rnXU .register_CardNumber__1Jhls {
  font-size: 8px;
}
.register_card__3AIxr.register_backface__3rnXU .register_magnetic-strip__uRtLk {
  position: absolute;
  top: 40px;
  left: 0;
  height: 45px;
  width: 100%;
  background: #000;
}
.register_card__3AIxr.register_backface__3rnXU .register_signature__22ADB {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 80px;
  height: 40px;
  width: 85%;
  border-radius: 6px;
  background: repeating-linear-gradient(#fff,
      #fff 3px,
      #efefef 0px,
      #efefef 9px);
}
.register_signature__22ADB i {
  color: #000;
  font-size: 12px;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #fff;
  margin-right: -30px;
  z-index: -1;
}
.register_card__3AIxr.register_backface__3rnXU .register_titrecredit__2uLRD {
  font-size: 8px;
  margin-top: 15px;
}
.fter{
    height: 500px;
    position: "absolute";
}
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

header {
    background-color: #4CAF50;
    color: white;
    padding: 1em 0;
    text-align: center;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    color: white;
    text-decoration: none;
}

main {
    padding: 20px;
    max-width: 1400px;
}

.service-cards, .secondary-service-cards, .partner-cards {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
}

.service-card, .secondary-service-card, .partner-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 200px;
}

button, .service-card a, .secondary-service-card a, .partner-card a {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

button:hover, .service-card a:hover, .secondary-service-card a:hover, .partner-card a:hover {
    background-color: #45a049;
}

footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer ul li {
    display: inline;
    margin: 0 10px;
}

footer ul li a {
    color: white;
    text-decoration: none;
}

form {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 20px;
}

label {
    font-weight: bold;
}

input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    align-self: flex-start;
}


body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

header {
    background-color: #4CAF50;
    color: white;
    padding: 1em 0;
    text-align: center;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    color: white;
    text-decoration: none;
}

main {
    padding: 20px;
}

.hero {
    text-align: center;
    margin-bottom: 40px;
}

.service-cards {
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
}

.service-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 300px;
}

.service-card img {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
}

button, .service-card a {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

button:hover, .service-card a:hover {
    background-color: #45a049;
}

footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer ul li {
    display: inline;
    margin: 0 10px;
}

footer ul li a {
    color: white;
    text-decoration: none;
}

form {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 20px;
}

label {
    font-weight: bold;
}

input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    align-self: flex-start;
}

.listePartenaires body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

.listePartenaires header {
    background-color: #4CAF50;
    color: white;
    padding: 1em 0;
    text-align: center;
}

.listePartenaires nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

.listePartenaires nav ul li {
    margin: 0 15px;
}

.listePartenaires nav ul li a {
    color: white;
    text-decoration: none;
}

.listePartenaires main {
    padding: 20px;
}

.listePartenaires .hero {
    text-align: center;
    margin-bottom: 40px;
}

.listePartenaires .prescription-upload, .pharmacy-responses, .partners {
    margin-top: 20px;
}

.listePartenaires form {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    margin-top: 20px;
}

.listePartenaires label {
    font-weight: bold;
}

.listePartenaires input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.listePartenaires button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.listePartenaires button:hover {
    background-color: #45a049;
}

.listePartenaires .partner-cards {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.listePartenaires .partner-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 200px;
}

.listePartenaires .pharmacy-responses img {
    display: block;
    margin: 0 auto 10px auto;
    width: 100%;
    max-width: 200px;
    height: auto;
}

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

header {
    background-color: #4CAF50;
    color: white;
    padding: 1em 0;
    text-align: center;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    color: white;
    text-decoration: none;
}

main {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
}

h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5em;
    font-weight: bold;
    color: #4CAF50;
}

.content {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 40px;
    gap: 40px;
    justify-content: space-between;
}

.partner-details {
    flex: 1 1;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.partner-details:hover {
    transform: scale(1.02);
}

.partner-details h3 {
    font-size: 1.75em;
    color: #4CAF50;
}

.partner-details p {
    font-size: 1.1em;
    margin: 10px 0;
    line-height: 1.6;
}

.partner-details button {
    display: inline-block;
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s, transform 0.3s;
}

.partner-details button:hover {
    background-color: #218838;
    transform: scale(1.05);
}

.partner-image {
    flex: 1 1;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s;
}

.partner-image:hover {
    transform: scale(1.02);
}

.partner-image img {
    width: 80%;
    height: auto;
    border-radius: 8px;
}

.contact-form {
    margin-top: 40px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.contact-form:hover {
    transform: scale(1.02);
}

.contact-form h3 {
    font-size: 1.75em;
    color: #007bff;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}

label {
    font-weight: bold;
    color: #343a40;
}

input, textarea {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1em;
    color: #495057;
    background-color: #fff;
    transition: border-color 0.3s;
}

input:focus, textarea:focus {
    border-color: #007bff;
    outline: none;
}

footer {
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 20px 0;
    position: relative;
    width: 100%;
    bottom: 0;
}

footer ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin: 0;
}

footer ul li {
    margin: 0 15px;
}

footer ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

footer ul li a:hover {
    color: #ffcc00;
}
.fontF {
  font-family: "Poppins";
}

@media (max-width< 779px) {
  .imglogo {
    max-width: 80%;
    margin-bottom: 15px;
  }
}


.offreWidth {
  width: 310px;
}

/* Styles généraux */
.custom-rounded {
  border-radius: 20px;
  /* Coins arrondis */
}

.custom-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Ombre personnalisée */
}

/* Grille avec flex et gestion du wrap */
.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Pour que les éléments s'ajustent sur plusieurs lignes si nécessaire */
}

.col-12,
.col-sm-6,
.col-md-3 {
  padding: 0 15px;
  /* Ajout de padding pour espacer les éléments */
}

.col-12 {
  flex: 0 0 100%;
  /* Sur les petits écrans, chaque élément prend toute la largeur */
}

.col-sm-6 {
  flex: 0 0 48%;
  /* Sur les écrans de taille moyenne, chaque élément prend environ 50% */
}

.col-md-3 {
  flex: 0 0 23%;
  /* Sur les écrans moyens et plus grands, chaque élément prend 23% */
  margin-bottom: 20px;
  /* Espacement entre les éléments */
}

/* Styles de la carte client */
.client-card {
  border: 1px solid #ddd;
  /* Cadre autour de chaque élément */
  padding: 20px;
  background-color: #fff;
  /* Fond blanc */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Légère ombre pour le cadre */
  text-align: center;
  /* Centrer le texte à l'intérieur */
  max-height: 400px;
  /* Hauteur minimale pour toutes les cartes */
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Assure que le contenu est bien réparti verticalement */
  margin-bottom: 20px;
  /* Espacement entre les cartes */
}

/* Éléments à l'intérieur de la carte client */
.client-card figure {
  margin: 0;
}

.client-card .carousel-text {
  font-style: italic;
  margin: 10px 0;
}

.client-card .about-border {
  height: 2px;
  background-color: #ddd;
  margin: 10px 0;
}

.client-card .carousel-title {
  font-weight: bold;
  margin: 5px 0;
}

.client-card .carousel-end-text {
  color: #777;
  margin: 5px 0;
}

/* Image en bas de la carte */
.client-card .carousel-bottom-fig img {
  width: 30px;
  /* Ajuste la taille de l'image en bas */
  height: auto;
  margin-top: 10px;
}

/* Pour les petits écrans : ajuster la taille de la carte */
@media (max-width: 768px) {
  .client-card {
    padding: 15px;
    /* Réduire le padding sur les petits écrans */
    max-height: none;
    /* Supprimer la hauteur maximale */
  }

  .col-sm-6 {
    flex: 0 0 100%;
    /* Sur les petits écrans, les éléments prennent 100% de la largeur */
  }
}

.temoignage {
  padding-top: 150px;
}






.containerc {
  margin: 0 auto;
  padding: 20px;
}



.steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.step {
  background: #fff;
  border: 2px solid #28a745;
  border-radius: 50%;
  padding: 20px;
  width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-left: 1.6rem !important;
}

.step-icon {
  font-size: 3rem;
  color: #28a745;
  margin-bottom: 10px;
}

.step-title {
  font-size: 22px;
  /* color: #28a745; */
  margin-bottom: 20px;
  font-weight: bolder;
}

.step-description {
  color: #666;
  font-size: 15px;
  line-height: 25px;

}

.step-number {
  position: absolute;
  top: 25px;
  left: 1px;
  background: #28a745;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.payment-options {
  text-align: left;
}

.header-top {
  position: fixed;
  width: 100%;
  z-index: 1;
  background: #e7e7e7;
}

/* A partir d'une largeur d'écran de 590px, le style `position: fixed` sera retiré */
@media (max-width: 589px) {
  .header-top {
    position: static;
  }
}

.breadcrumbs {
  font-size: 22px !important;
  margin-bottom: 5px;
}

.offres {


  margin-bottom: 200;
  margin-top: 50
}

@media (max-width: 775px) {
  .offres {
    margin-bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*  height: 100vh; */
    /* Assure que l'élément prend toute la hauteur de l'écran */
    text-align: center;
    /* Centrer le texte si nécessaire */
    margin: 0 auto;
    /* Centrer horizontalement si l'élément a une largeur fixe */
  }
}

.temoignages {
  margin-bottom: 100;
}

@media (max-width: 775px) {
  .temoignages {
    margin-bottom: 25;
  }
}


/* header */

@media (min-width: 571px) {
  .paddingheader {
    padding-top: 60px;
  }
}

/* home */
@media (max-width: 779px) {
  .img-fluid {
    max-width: 60%;
  }
}

@media (min-width: 571px) {
  .commentcamarche {
    margin-top: 5px;
  }
}

@media (min-width: 571px) {
  .temoignagepadding {
    padding: 15px 50px 15px 100px;
    margin-bottom: 70px;
    /* style={{ paddingBottom: 100, paddingTop: 50, paddingLeft: 15, paddingRight: 15}} */
  }
}


@media (min-width: 601px) {
  .imagehome {
    height: 500;
  }
}

/* userconnect */

@media (min-width: 1401px) {
  .userconnect {
    margin-top: 0px;
    margin-left: -110px
  }
}


/* creditcard */
.credit-card {
  background: linear-gradient(to right, #3D3B80, #38b873);
  /* Fond vert plus foncé */
  width: 365px;
  height: 230px;
  border-radius: 10px;
  padding: 13px;
  color: white;
  /* Texte en blanc */
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.widthRecapitulatif {
  width: 400px;
}

@media (max-width< 500px) {
  .widthRecapitulatif {
    width: 300px;
  }
}

.credit-card .logo {
  width: 80px;
  position: absolute;
  top: 15px;
  left: 20px;
}

.credit-card .avatar {

  height: 35px;
  /*  position: absolute; */
  /* top: 15px;
    right: 20px; */
}

.credit-card .number {
  font-size: 1.2em;
  letter-spacing: 2px;
  /*  margin-top: 50px; */
  font-weight: bold;
}

.credit-card .name {
  /* margin-top: 20px; */
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 500;
}

.credit-card .expiration {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 0.9em;
  font-weight: 500;
}

/* intermediaire */

.statisfait {
  position: absolute;

  width: 236px;
  height: 91px;
  background-color: #E4FCEA;
  border-bottom-left-radius: 92%;
  font-size: 14px;
  line-height: 22px;
  font-family: 'poppins_medium';
  font-size: 14px;
  line-height: 22px;
  text-align: end;
}

.divblue {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 4px;
  cursor: pointer;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #AEB1BE;
  border-radius: .25rem;
  transition: all 400ms;
}

.divblue:hover {
  border-color: #4A87FF;
  /* Change de couleur de bordure au survol */
  box-shadow: inset 0px 0px 0px 1px #4A87FF;
  ;
  /* Ajout d'un box-shadow */
}

#testToot span {
  font-weight: 900;
}

.divblue1 {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 4px;
  cursor: pointer;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #AEB1BE;
  border-radius: .25rem;
  transition: all 400ms;
}

.divblue1:hover {
  border-color: #4A87FF;
  /* Change de couleur de bordure au survol */
  box-shadow: inset 0px 0px 0px 1px #4A87FF;
  ;
  /* Ajout d'un box-shadow */
}


/* page intermediare */
.nomarge {
  margin-left: 3rem;
}

@media (max-width: 1224px) {
  .nomarge {
    margin-left: 0 !important;

  }
}

/* Par défaut (grandes tailles d'écran), vous pouvez garder le padding complet */
.mydiv {
  padding: 22px 30px 30px 30px;
}

/* Sur mobile (écran < 768px), vous appliquez uniquement le padding-top */
@media (max-width: 768px) {
  .mydiv {
    padding: 22px 5px 0 5px;

  }
}


.phonenumberclass input {
  width: 100% !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: 36px !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  color: var(--e-global-color-text) !important;
  border: none !important;
  border-bottom: 1px solid var(--e-global-color-secondary) !important;
}

.phonenumberclass {
  height: 35px !important;
}

.phoneClass {
  height: 55px !important;
  width: 100% !important;
}

.phoneClass input {
  height: 55px !important;
  width: 100% !important;
}

.checkboxclass1 input[type=checkbox] {
  border: 0px;
  width: 9% !important;
  height: 1.3em !important;
  margin-top: 9px !important;
}

.checkboxclass input[type=checkbox] {
  border: 0px;
  width: 9% !important;
  height: 1.3em !important;
  margin-top: 1px !important;
}

.labelInsc {
  margin-left: 13px !important;

  /*   margin-top: 12px !important; */
}

.labelInscr {
  margin-left: 24px !important;

  /*   margin-top: 12px !important; */
}


.is-invalid {
  border-color: red !important;


}

small {
  font-size: 12px !important;
}

.text-danger {
  color: red !important;
  margin-top: 5px;
}

.justifyarround {
  justify-content: space-around !important;
  align-items: center !important;
}

.imageProfil {
  height: 20px;
  width: 21px;
  margin-top: 5px;
  margin-left: 3px;
  cursor: pointer;
}

.imageProfil2 {
  height: 20px;
  width: 21px;
  margin-top: -6px;
  margin-left: 3px;
  cursor: pointer;
}

.imageProfil4 {
  height: 20px;
  width: 21px;
  margin-top: 5px;
  margin-right: 10px;
}

.imageProfil6 {
  height: 25px;
  width: 26px;
  margin-top: 5px;
  margin-right: 10px;
}

.imageProfil1 {
  height: 30px;
  width: 45px;
}

.imageProfil2 {
  height: 40px;
  width: 40px;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.imageProfil3 {
  height: 30px;
  width: 30px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.settingCss {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
}

.imageProfil7 {
  height: 50px;
  width: 50px;
  margin-top: 5px;
}

/* input file  */

.upload {
  width: 100px;
  position: relative;
  margin: auto;
}

.upload img {
  border-radius: 50%;
  border: 6px solid #eaeaea;
}
.positionDescription1{
  width: 350px !important;
  font-style: normal !important;
}
.lineheight5{
  line-height: 5px !important;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* Bord gauche transparent */
  border-right: 10px solid transparent; /* Bord droit transparent */
  border-bottom: 10px solid rgb(10, 12, 13);  /* Bord bas coloré comme la div */
  position: absolute;
  top: -10px; /* Place le triangle juste au-dessus de la div */
  left: 50%;  /* Place le triangle au milieu horizontalement */
  transform: translateX(-50%); /* Ajustement pour centrer exactement */
}

.upload .round {
  position: absolute;
  bottom: 10px;
  right: 0;
  background: #00B4FF;
  width: 32px;
  height: 32px;
  line-height: 33px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
}

.upload .round input[type="file"] {
  position: absolute;
  transform: scale(2);
  opacity: 0;
}

input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}


.imageAdministration {
  width: 500px;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
}

.buttoyellow {
  /* width: 75%; */
  width: 170px;
  border-radius: 40px;
  height: 60px;
}

.womencall {
  width: 400px;
  height: 930px;
  height: 100%;
  object-fit: cover;
  border-radius: 0 10px 10px 0;
}

.inputcss1 input {
  height: 75px !important;
}

.inputcss1 PhoneInput {
  height: 75px !important;
}

.phoneClass2 {
  height: 75px !important;
}

.nexticon {
  width: 40px;
  margin-left: 10px;
}

.serviceStyle {
  translate: none;
  rotate: none;
  scale: none;
  transform: translate(0px, 0px);
  opacity: 1;
}

.labelstyle {
  height: 12rem;
  border: 0.3rem solid rgba(135, 146, 164, 0);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  border-radius: 0.5rem;
  padding: 3rem 3rem 2.5rem;
  cursor: pointer;
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  color: #323842;
  font-weight: 600;
}

.mil-custom-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.mil-custom-checkbox .mil-checkbox-checkmark:after {
  content: "";
  position: absolute;
  margin-top: -0.3rem;
  width: 0.6rem;
  height: 1rem;
  border: solid #fff;
  border-width: 0 0.2rem 0.2rem 0;
  transform: rotate(45deg) scale(0);
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
}

.span1 {
  padding-right: 6rem;
  font-size: 1.8rem;
}

.imagedroit {
  width: 35px;
  margin-right: 20px;
}

.imageServiceCall {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

@media (max-width: 1024px) {

  /* Tes styles pour les écrans en dessous de 768px */
  .imageAdministration {
    width: 330px;
    height: 400px;
    object-fit: cover;
  }

  .packresponsive {
    font-size: 17px !important;
  }

  .packresponsive-1 {
    width: 286px !important;
  }

  .packstyle {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin: 7px !important;
  }

  .divpack2 {
    margin-bottom: 27px !important;
  }
}

@media (max-width: 1024px) {
  .hidden {
    display: none !important;
  }

  .widthhidden {
    width: 99% !important;
  }

}

.marginbottom104 {
  margin-bottom: -104px !important;
}

@media (max-width > 700px) {
  .order1700 {
    display: none !important;
    visibility: hidden !important;
  }
}
@media (min-width: 480px) {
  .nonedispaly {
   display: none !important;
   
  }
}
@media only screen and (max-width: 991px) {
  .navbar-toggler span:nth-child(1) {
      transform: rotate(50deg);
      transform-origin: 0% 80%;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-collapse a {
      padding: 15px 0px !important;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-toggler span:nth-child(3) {
      transform: rotate(-53deg);
      transform-origin: 15% 100%;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-nav .active > a {
      color:black !important;
  }
}
@media only screen and (max-width: 480px) {

  /* Styles pour écrans de 480px ou moins */
  header {
    padding: 0 !important;
  }

  .navbar .headerwitdh{
    display: flex;
  }

  .navbar-toggler {
    width: 48px !important;
  }

  .main-header .navbar-brand img {
    width: 130px !important;
  }

  .main-header {
    margin: 0 !important;
    padding: 0 !important;
  }

  .navbar-expand-lg {
    height: 82px !important;
  }


  .navbar-collapse {
    background-color: #e7e7e7 !important;
    width: 160px !important;
    margin-top: 10px;
    margin-right: -13px;
  }

  button:hover,
  .service-card a:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .imageProfil3 {
    height: 40px;
    width: 40px;
    margin-bottom: 10px;
    border-radius: 50%;
  }
  .displaynone1{
    display: none;
  }


}

/* Pour les téléphones avec une largeur maximale de 600px */
@media (max-width: 700px) {
  .nav-linkblack {
    display: none !important;
  }

  .width400 {
    width: 370px !important;
  }

  .margin0 {
    margin: 0 !important;
  }

  .flexwrap {
    flex-wrap: wrap !important;
  }

  .width300M {
    width: 300px !important;
  }

  .marginTop10 {
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 1000px) {
  .dflexcolum {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width:820px) {
  .marginBottom220 {
    margin-bottom: 230px !important;
  }

  .menudropdown {
    display: block !important;
  }

  .d-flex-div {
    display: flex;
    justify-content: center;
  }

  .width400 {
    width: 500px;
  }

  .marginleftright0 {
    margin-left: 0 ! important;
    margin-right: 0 !important;
  }

  .displaynone {
    display: none;
    visibility: hidden;
    width: 0px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .marginleft0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .flexcolumn {
    flex-direction: column;
  }

  .with1001 {
    width: 100% !important;
  }

}

.height70 {
  height: 70px !important;
}


@media (min-width: 769px) and (max-width: 1023px) {
  .widthrespon{
    width: 700px !important;
    min-width: 700px !important;

  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .widthrespon{
    min-width: 300px !important; }
  .fontSize{
    font-size: 12px !important;
  }
 .fontSize2{
  font-size: 25px !important;
 }
 .width346M{
  width: 346px !important;
 }
 .width220{
  width: 220px !important;
 }
 .width0{
  width: 0px !important;
 }
}



/* splashscreem */
.splashscreen-sp {
  /* background-color: #faf6eb; */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  width: 100vw;  
  box-sizing: border-box;
  overflow: hidden;
}

.container-sp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px; 
  width: 100%;
  text-align: center;
}

.imageWrapper-sp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin-bottom: 40px;
}

.primaryImage-sp {
  object-fit: contain;
  object-position: center;
  width: 130%;
}

.secondaryImage-sp {
  aspect-ratio: 1.61;
  object-fit: contain;
  object-position: center;
  width: 250px;
  max-width: 100%;
  margin-top: 10px;
}

.loadingDots-sp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.loadingDots-sp span {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #333;
  border-radius: 50%;
  animation: loadingAnimation 1.4s infinite ease-in-out;
}

.loadingDots-sp span:nth-child(1) {
  animation-delay: 0s;
}
.loadingDots-sp span:nth-child(2) {
  animation-delay: 0.2s;
}
.loadingDots-sp span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingAnimation {
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0.3;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}


.loading-container-sp {
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}

.dot-sp {
  width: 40px;
  height: 40px;
  background-color: #4caf50;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot-sp:nth-child(2) {
  animation-delay: 0.2s;
}

.dot-sp:nth-child(3) {
  animation-delay: 0.4s;
}

.dot-sp:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes pulse {
  0%, 100% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(0.9);
      opacity: 0.7;
  }
}


@media (min-width: 320px) and (max-width: 600px) {

.primaryImage-sp {
  object-fit: contain;
  object-position: center;
  width: 60% ;
}
.loading-container-sp {
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  grid-gap: 50px;
  gap: 50px;
}


}
.mil-top-panel .container {
    height: 100%;
  }
  .mil-p-0-f {
    padding-bottom: 10rem;
  }
/* -------------------------------------------

calculator

------------------------------------------- */
.mil-calculator .mil-custom-select {
    width: 100%;
    height: 9rem;
    position: relative;
    z-index: 2;
  }
  .mil-calculator .mil-custom-select i {
    height: 100%;
  }
  .mil-calculator .mil-custom-select .mil-select-button {
    border: none;
    height: 9rem;
  }
  .mil-calculator .mil-custom-select .mil-select-button .mil-selected-value {
    color: #323842;
    opacity: 1;
  }
  .mil-calculator .mil-custom-select .mil-select-dropdown {
    top: 10rem;
    z-index: 9;
  }
  .mil-calculator .mil-calc-panel {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 9rem;
    background-color: #fff;
    padding: 3rem;
    padding-bottom: 0;
  }
  .mil-calculator .mil-calc-panel-2 {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 9rem;
    background-color: #fff;
    padding: 1.5rem 3rem;
    padding-bottom: 0;
  }
  .mil-calculator .mil-service-item {
    display: block;
    background-color: #fff;
    height: 9rem;
  }
  .mil-calculator .mil-total-price {
    padding: 3rem;
    padding-bottom: 0;
  }
  .mil-calculator .mil-total-price .mil-tp-frame {
    display: flex;
    justify-content: space-between;
    border-radius: 9rem;
    align-items: center;
  }
  .mil-calculator .mil-total-price .mil-tp-frame .mil-price-number {
    min-width: 28rem;
  }
  .mil-calculator .mil-total-price .mil-tp-frame button {
    border: none;
    cursor: pointer;
  }
  @media screen and (max-width: 390px) {
    .mil-calculator .mil-total-price .mil-tp-frame {
      flex-direction: column;
      align-items: flex-start;
    }
    .mil-calculator .mil-total-price .mil-tp-frame .mil-price-number {
      margin-bottom: 3rem;
    }
  }
  .mil-aic {
    display: flex;
    align-items: center;
  }
  .mil-jcb {
    display: flex;
    justify-content: space-between;
  }
  .mil-mb-15 {
    margin-bottom: 1.5rem;
  }
  .mil-sm-mb-15 {
    margin-bottom: 1.5rem;
  }
  

.mil-input-frame {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }
  .mil-input-frame input, .mil-input-frame .mil-select-button {
    width: 100%;
    height: 7rem;
    padding-left: 3rem;
    font-size: 1.6rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Outfit", sans-serif;
    border: solid 0.1rem rgba(135, 146, 164, 0.3);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame input .mil-selected-value, .mil-input-frame .mil-select-button .mil-selected-value {
    opacity: 0.6;
  }
  .mil-input-frame input .mil-selected-value.mil-selected, .mil-input-frame .mil-select-button .mil-selected-value.mil-selected {
    opacity: 1;
  }
  .mil-input-frame input::placeholder, .mil-input-frame .mil-select-button::placeholder {
    color: #8792a4;
  }
  .mil-input-frame input:hover, .mil-input-frame input:focus, .mil-input-frame .mil-select-button:hover, .mil-input-frame .mil-select-button:focus {
    outline: inherit;
  }
  .mil-input-frame input:focus + i, .mil-input-frame .mil-select-button:focus + i {
    filter: grayscale(0);
    opacity: 1;
  }
  .mil-input-frame textarea {
    height: 22rem;
    width: 100%;
    padding: 2.5rem 3rem;
    font-size: 1.6rem;
    font-family: "Outfit", sans-serif;
    border: solid 0.1rem rgba(135, 146, 164, 0.2);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame textarea::placeholder {
    color: #8792a4;
  }
  .mil-input-frame textarea:hover, .mil-input-frame textarea:focus {
    outline: inherit;
  }
  .mil-input-frame textarea:focus + i {
    filter: grayscale(0);
    opacity: 1;
  }
  .mil-input-frame.mil-custom-select i {
    transform: rotate(-90deg);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame i {
    opacity: 0.2;
    border-radius: 50%;
    position: absolute;
    filter: grayscale(100%);
    right: 0.1rem;
    top: 0.1rem;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6.8rem;
    width: 6.8rem;
    pointer-events: none;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame .mil-select-dropdown {
    position: absolute;
    top: 8.5rem;
    z-index: 999;
    list-style: none;
    width: 100%;
    background-color: #5A8CF5;
    padding: 1.5rem;
    transform: translateY(-0.7rem) scale(0.9);
    opacity: 0;
    visibility: hidden;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame .mil-select-dropdown li {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame .mil-select-dropdown li label {
    line-height: 100%;
    color: #fff;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    padding: 1.5rem 1.5rem;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame .mil-select-dropdown li label:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .mil-input-frame .mil-select-dropdown li:hover, .mil-input-frame .mil-select-dropdown li input:checked ~ label {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .mil-input-frame .mil-select-dropdown li input:focus ~ label {
    background-color: #8792a4;
  }
  .mil-input-frame .mil-select-dropdown li input[type=radio] {
    position: absolute;
    left: 0;
    opacity: 0;
  }
  .mil-input-frame.mil-active i {
    transform: rotate(0);
    filter: grayscale(0);
    opacity: 1;
  }
  .mil-input-frame.mil-active .mil-select-dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) scale(1);
  }
  .mil-input-frame:hover i, .mil-input-frame:focus i {
    filter: grayscale(0);
    opacity: 1;
  }  

  .mil-input-frame.mil-custom-select i {
    transform: rotate(-90deg);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-calculator .mil-custom-select {
    width: 100%;
    height: 9rem;
    position: relative;
    z-index: 2;
  }
  .mil-calculator .mil-custom-select i {
    height: 100%;
  }
  .mil-calculator .mil-custom-select .mil-select-button {
    border: none;
    height: 9rem;
  }
  .mil-calculator .mil-custom-select .mil-select-button .mil-selected-value {
    color: #323842;
    opacity: 1;
  }
  .mil-calculator .mil-custom-select .mil-select-dropdown {
    top: 10rem;
    z-index: 9;
  }
  .mil-up {
    will-change: transform;
  }
  .testrow{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-around ;

  }

  @media (max-width: 768px) {
   .widthService{
    width: 325px !important;}
    .positionDescription{
      top: 100px !important;
      left:166px !important;
      width: 300px !important;
      max-height: 350px !important;
    }
   }
    
  @media (max-width: 1280px) {
      .marginbottomService{
      margin-bottom:-550px !important;
      }
      .marginbottomService2{
        margin-bottom:-250px !important;
        }
      .hiddenimage{
        display: none;
      }
      .marginbottom130{
        margin-bottom: -250px !important;
      }
      /* .width400{
        width: 600px !important;
      } */
       }
  
  .mil-up {
    opacity: 0;
  }
  .mil-discount-card .mil-dscnt-part .mil-up-text {
    padding-top: 4rem;
    height: 20rem;
    width: 20rem;
    position: absolute;
    z-index: 1;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mil-up-text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
  }
  .mil-br-md {
    border-radius: 1rem;
  }
  .mil-hero-form-frame.mil-bg-m-4 {
    background-color: rgba(255, 255, 255, 0.9);
    border: solid 0.1rem white;
    -webkit-backdrop-filter: blur(5rem);
            backdrop-filter: blur(5rem);
  }
  .mil-bg-m-4 {
    background-color: #fff !important;
  }
  .mil-fs-18 {
    font-size: 14px !important;
    margin-bottom: 10px;
    /* white-space: nowrap !important; */
    
  }
  .mil-fw-600 {
    font-weight: 600;
  }
  .mil-m-1 {
    color: #323842;
  }
  .mil-text-link.mil-a-2:after {
    background-color: #5A8CF5;
  }
  /* -------------------------------------------

services

------------------------------------------- */
.mil-service-card {
    padding: 4rem;
    display: block;
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-service-card .mil-card-icon {
    width: 60px;
  }
  .mil-service-card:hover {
    transform: scale(1.01);
  }
  .mil-service-card:hover .mil-btn  {
    background-color: #EDCE29 !important;
  }
  .bdf{
    background-color: #EDCE29 !important;
  }
  
  .mil-service-card-long .mil-img-frame {
    width: 100%;
    height: 100%;
    min-height: 30rem;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
  }
  .mil-service-card-long .mil-img-frame img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .mil-service-card-long .mil-inner-frame {
    padding: 6rem;
    height: 100%;
  }
  /* -------------------------------------------

checkbox

------------------------------------------- */
.mil-custom-checkbox {
    height: 5rem;
    border: 2px solid rgba(135, 146, 164, 0);
    background-color: #fff;
    position: relative;
    overflow: hidden;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            /* align-items: flex-end; */
    border-radius: 0.5rem;
    padding: 3rem 1.2rem;
    cursor: pointer;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-custom-checkbox.mil-checked {
    border-color: #EDCE29 !important;
  }
  .mil-custom-checkbox.mil-checked.mil-already-included {
    pointer-events: none;
  }
  .mil-custom-checkbox.mil-checked.mil-already-included:after {
    content: "Déjà inclus";
    position: absolute;
    top: 0;
    left: 2rem;
    font-weight: 400;
    font-size: 10px;
    background-color: #EDCE29;
    padding: 0.3rem 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-custom-checkbox.mil-unavailable {
    opacity: 0.4;
    pointer-events: none;
    border: none;
  }
  .mil-custom-checkbox.mil-unavailable:after {
    content: "Temporarily unavailable";
    position: absolute;
    top: 0;
    left: 3rem;
    font-weight: 400;
    font-size: 12px;
    background-color: #f3f4f6;
    padding: 0.3rem 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-custom-checkbox .mil-checkbox-checkmark {
    position: absolute;
    bottom: 1.6rem;
    right: 2.4rem;
    height: 22px;
    width: 22px;
    background-color: #f3f4f6;
    border: 0.1rem solid rgba(135, 146, 164, 0.4);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-custom-checkbox input[type=checkbox]:checked ~ .mil-checkbox-checkmark {
    background-color: #5A8CF5;
    border-color: #5A8CF5;
  }
  .mil-custom-checkbox .mil-checkbox-checkmark:after {
    content: "";
    position: absolute;
    margin-top: -0.3rem;
    width: .3rem;
    height: 0.6rem;
    border: solid #fff;
    border-width: 0 .1rem 0.1rem 0;
    transform: rotate(45deg) scale(0);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-custom-checkbox input[type=checkbox]:checked ~ .mil-checkbox-checkmark:after {
    transform: rotate(45deg) scale(1);
  }
  .mil-custom-checkbox .mil-service-name {
    padding-right: 6rem;
  }
  .mil-custom-checkbox input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .mil-custom-checkbox:hover {
    transform: scale(1.01);
  }
  .mil-custom-checkbox:hover .mil-checkbox-checkmark {
    border: 0.2rem solid #5A8CF5;
  }

  /* .htmltemplate {
    font-size: 9px;
  }
  @media (max-width: 1800px) {
    html {
      font-size: 8px;
    }
  }
  @media (max-width: 1200px) {
    html {
      font-size: 7px;
    }
  }
  @media (max-width: 992px) {
    html {
      font-size: 6px;
    }
  } */
.mil-fs-36 {
    font-size: 3.6rem;
}
.mil-btn.mil-icon-btn {
  padding: 0 1rem 0 4rem !important;
}

.mil-btn {
  border: none  !important;
  text-transform: uppercase  !important;
  letter-spacing: 0.2rem  !important;
  font-weight: 500  !important;
  /* font-family: "Outfit", sans-serif  !important; */
  font-size: 1.4rem  !important;
  white-space: nowrap  !important;
  color: #323842  !important;
  padding: 0 4rem  !important;
  display: inline-flex  !important
;
  align-items: center  !important;
  height: 7rem  !important;
  padding: 0 4rem  !important;
  transition: 0.4scubic-bezier(0, 0, 0.3642, 1)  !important;
}
.mil-br-xl {
  border-radius: 10rem  !important;
}
.mil-bg-a-2 {
  background-color: #5A8CF5 !important;
}
@media (max-width: 679px) {

  .widhheight{
    width: 335px !important;
    height: 580px !important;
    border-radius: 10px !important;

  }
  .fontSizetext{
    font-size: 17px !important;
  }
  .hiddenbutton{
    visibility: hidden;
  }
  .buttoyellowWdth{
    width: 110px;
  }
  .marginright38{
    margin-right: 38px;
  }
  .marginleft28{
    margin-left: 38px;
  }
  .flexwrap1{
    flex-wrap: wrap;
  }
  .widthprofilbnef{
    width: 332px !important;
  }
  .withtext{
    font-size: 16px !important;
    margin-top: 20px;
  }
  .with300px{
    width: 300px !important;
  }
}
.marginbottom130{
  margin-bottom: -130px;
}
.dflexresposive{
  justify-content: center !important;
  flex-wrap: wrap !important;
  align-items: center !important;
}
