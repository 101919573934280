.fontF {
  font-family: "Poppins";
}

@media (max-width< 779px) {
  .imglogo {
    max-width: 80%;
    margin-bottom: 15px;
  }
}


.offreWidth {
  width: 310px;
}

/* Styles généraux */
.custom-rounded {
  border-radius: 20px;
  /* Coins arrondis */
}

.custom-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Ombre personnalisée */
}

/* Grille avec flex et gestion du wrap */
.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Pour que les éléments s'ajustent sur plusieurs lignes si nécessaire */
}

.col-12,
.col-sm-6,
.col-md-3 {
  padding: 0 15px;
  /* Ajout de padding pour espacer les éléments */
}

.col-12 {
  flex: 0 0 100%;
  /* Sur les petits écrans, chaque élément prend toute la largeur */
}

.col-sm-6 {
  flex: 0 0 48%;
  /* Sur les écrans de taille moyenne, chaque élément prend environ 50% */
}

.col-md-3 {
  flex: 0 0 23%;
  /* Sur les écrans moyens et plus grands, chaque élément prend 23% */
  margin-bottom: 20px;
  /* Espacement entre les éléments */
}

/* Styles de la carte client */
.client-card {
  border: 1px solid #ddd;
  /* Cadre autour de chaque élément */
  padding: 20px;
  background-color: #fff;
  /* Fond blanc */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Légère ombre pour le cadre */
  text-align: center;
  /* Centrer le texte à l'intérieur */
  max-height: 400px;
  /* Hauteur minimale pour toutes les cartes */
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Assure que le contenu est bien réparti verticalement */
  margin-bottom: 20px;
  /* Espacement entre les cartes */
}

/* Éléments à l'intérieur de la carte client */
.client-card figure {
  margin: 0;
}

.client-card .carousel-text {
  font-style: italic;
  margin: 10px 0;
}

.client-card .about-border {
  height: 2px;
  background-color: #ddd;
  margin: 10px 0;
}

.client-card .carousel-title {
  font-weight: bold;
  margin: 5px 0;
}

.client-card .carousel-end-text {
  color: #777;
  margin: 5px 0;
}

/* Image en bas de la carte */
.client-card .carousel-bottom-fig img {
  width: 30px;
  /* Ajuste la taille de l'image en bas */
  height: auto;
  margin-top: 10px;
}

/* Pour les petits écrans : ajuster la taille de la carte */
@media (max-width: 768px) {
  .client-card {
    padding: 15px;
    /* Réduire le padding sur les petits écrans */
    max-height: none;
    /* Supprimer la hauteur maximale */
  }

  .col-sm-6 {
    flex: 0 0 100%;
    /* Sur les petits écrans, les éléments prennent 100% de la largeur */
  }
}

.temoignage {
  padding-top: 150px;
}






.containerc {
  margin: 0 auto;
  padding: 20px;
}



.steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.step {
  background: #fff;
  border: 2px solid #28a745;
  border-radius: 50%;
  padding: 20px;
  width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-left: 1.6rem !important;
}

.step-icon {
  font-size: 3rem;
  color: #28a745;
  margin-bottom: 10px;
}

.step-title {
  font-size: 22px;
  /* color: #28a745; */
  margin-bottom: 20px;
  font-weight: bolder;
}

.step-description {
  color: #666;
  font-size: 15px;
  line-height: 25px;

}

.step-number {
  position: absolute;
  top: 25px;
  left: 1px;
  background: #28a745;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.payment-options {
  text-align: left;
}

.header-top {
  position: fixed;
  width: 100%;
  z-index: 1;
  background: #e7e7e7;
}

/* A partir d'une largeur d'écran de 590px, le style `position: fixed` sera retiré */
@media (width < 590px) {
  .header-top {
    position: static;
  }
}

.breadcrumbs {
  font-size: 22px !important;
  margin-bottom: 5px;
}

.offres {


  margin-bottom: 200;
  margin-top: 50
}

@media (max-width: 775px) {
  .offres {
    margin-bottom: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*  height: 100vh; */
    /* Assure que l'élément prend toute la hauteur de l'écran */
    text-align: center;
    /* Centrer le texte si nécessaire */
    margin: 0 auto;
    /* Centrer horizontalement si l'élément a une largeur fixe */
  }
}

.temoignages {
  margin-bottom: 100;
}

@media (max-width: 775px) {
  .temoignages {
    margin-bottom: 25;
  }
}


/* header */

@media (width> 570px) {
  .paddingheader {
    padding-top: 60px;
  }
}

/* home */
@media (width< 780px) {
  .img-fluid {
    max-width: 60%;
  }
}

@media (width > 570px) {
  .commentcamarche {
    margin-top: 5px;
  }
}

@media (width > 570px) {
  .temoignagepadding {
    padding: 15px 50px 15px 100px;
    margin-bottom: 70px;
    /* style={{ paddingBottom: 100, paddingTop: 50, paddingLeft: 15, paddingRight: 15}} */
  }
}


@media (width > 600px) {
  .imagehome {
    height: 500;
  }
}

/* userconnect */

@media (width > 1400px) {
  .userconnect {
    margin-top: 0px;
    margin-left: -110px
  }
}


/* creditcard */
.credit-card {
  background: linear-gradient(to right, #3D3B80, #38b873);
  /* Fond vert plus foncé */
  width: 365px;
  height: 230px;
  border-radius: 10px;
  padding: 13px;
  color: white;
  /* Texte en blanc */
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.widthRecapitulatif {
  width: 400px;
}

@media (max-width< 500px) {
  .widthRecapitulatif {
    width: 300px;
  }
}

.credit-card .logo {
  width: 80px;
  position: absolute;
  top: 15px;
  left: 20px;
}

.credit-card .avatar {

  height: 35px;
  /*  position: absolute; */
  /* top: 15px;
    right: 20px; */
}

.credit-card .number {
  font-size: 1.2em;
  letter-spacing: 2px;
  /*  margin-top: 50px; */
  font-weight: bold;
}

.credit-card .name {
  /* margin-top: 20px; */
  text-transform: uppercase;
  font-size: 1em;
  font-weight: 500;
}

.credit-card .expiration {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 0.9em;
  font-weight: 500;
}

/* intermediaire */

.statisfait {
  position: absolute;

  width: 236px;
  height: 91px;
  background-color: #E4FCEA;
  border-bottom-left-radius: 92%;
  font-size: 14px;
  line-height: 22px;
  font-family: 'poppins_medium';
  font-size: 14px;
  line-height: 22px;
  text-align: end;
}

.divblue {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 4px;
  cursor: pointer;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #AEB1BE;
  border-radius: .25rem;
  transition: all 400ms;
}

.divblue:hover {
  border-color: #4A87FF;
  /* Change de couleur de bordure au survol */
  box-shadow: inset 0px 0px 0px 1px #4A87FF;
  ;
  /* Ajout d'un box-shadow */
}

#testToot span {
  font-weight: 900;
}

.divblue1 {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border-radius: 4px;
  cursor: pointer;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #AEB1BE;
  border-radius: .25rem;
  transition: all 400ms;
}

.divblue1:hover {
  border-color: #4A87FF;
  /* Change de couleur de bordure au survol */
  box-shadow: inset 0px 0px 0px 1px #4A87FF;
  ;
  /* Ajout d'un box-shadow */
}


/* page intermediare */
.nomarge {
  margin-left: 3rem;
}

@media (width < 1225px) {
  .nomarge {
    margin-left: 0 !important;

  }
}

/* Par défaut (grandes tailles d'écran), vous pouvez garder le padding complet */
.mydiv {
  padding: 22px 30px 30px 30px;
}

/* Sur mobile (écran < 768px), vous appliquez uniquement le padding-top */
@media (max-width: 768px) {
  .mydiv {
    padding: 22px 5px 0 5px;

  }
}


.phonenumberclass input {
  width: 100% !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  height: 36px !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  color: var(--e-global-color-text) !important;
  border: none !important;
  border-bottom: 1px solid var(--e-global-color-secondary) !important;
}

.phonenumberclass {
  height: 35px !important;
}

.phoneClass {
  height: 55px !important;
  width: 100% !important;
}

.phoneClass input {
  height: 55px !important;
  width: 100% !important;
}

.checkboxclass1 input[type=checkbox] {
  border: 0px;
  width: 9% !important;
  height: 1.3em !important;
  margin-top: 9px !important;
}

.checkboxclass input[type=checkbox] {
  border: 0px;
  width: 9% !important;
  height: 1.3em !important;
  margin-top: 1px !important;
}

.labelInsc {
  margin-left: 13px !important;

  /*   margin-top: 12px !important; */
}

.labelInscr {
  margin-left: 24px !important;

  /*   margin-top: 12px !important; */
}


.is-invalid {
  border-color: red !important;


}

small {
  font-size: 12px !important;
}

.text-danger {
  color: red !important;
  margin-top: 5px;
}

.justifyarround {
  justify-content: space-around !important;
  align-items: center !important;
}

.imageProfil {
  height: 20px;
  width: 21px;
  margin-top: 5px;
  margin-left: 3px;
  cursor: pointer;
}

.imageProfil2 {
  height: 20px;
  width: 21px;
  margin-top: -6px;
  margin-left: 3px;
  cursor: pointer;
}

.imageProfil4 {
  height: 20px;
  width: 21px;
  margin-top: 5px;
  margin-right: 10px;
}

.imageProfil6 {
  height: 25px;
  width: 26px;
  margin-top: 5px;
  margin-right: 10px;
}

.imageProfil1 {
  height: 30px;
  width: 45px;
}

.imageProfil2 {
  height: 40px;
  width: 40px;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.imageProfil3 {
  height: 30px;
  width: 30px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.settingCss {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
}

.imageProfil7 {
  height: 50px;
  width: 50px;
  margin-top: 5px;
}

/* input file  */

.upload {
  width: 100px;
  position: relative;
  margin: auto;
}

.upload img {
  border-radius: 50%;
  border: 6px solid #eaeaea;
}
.positionDescription1{
  width: 350px !important;
  font-style: normal !important;
}
.lineheight5{
  line-height: 5px !important;
}
.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* Bord gauche transparent */
  border-right: 10px solid transparent; /* Bord droit transparent */
  border-bottom: 10px solid rgb(10, 12, 13);  /* Bord bas coloré comme la div */
  position: absolute;
  top: -10px; /* Place le triangle juste au-dessus de la div */
  left: 50%;  /* Place le triangle au milieu horizontalement */
  transform: translateX(-50%); /* Ajustement pour centrer exactement */
}

.upload .round {
  position: absolute;
  bottom: 10px;
  right: 0;
  background: #00B4FF;
  width: 32px;
  height: 32px;
  line-height: 33px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
}

.upload .round input[type="file"] {
  position: absolute;
  transform: scale(2);
  opacity: 0;
}

input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}


.imageAdministration {
  width: 500px;
  height: 500px;
  object-fit: cover;
  border-radius: 20px;
}

.buttoyellow {
  /* width: 75%; */
  width: 170px;
  border-radius: 40px;
  height: 60px;
}

.womencall {
  width: 400px;
  height: 930px;
  height: 100%;
  object-fit: cover;
  border-radius: 0 10px 10px 0;
}

.inputcss1 input {
  height: 75px !important;
}

.inputcss1 PhoneInput {
  height: 75px !important;
}

.phoneClass2 {
  height: 75px !important;
}

.nexticon {
  width: 40px;
  margin-left: 10px;
}

.serviceStyle {
  translate: none;
  rotate: none;
  scale: none;
  transform: translate(0px, 0px);
  opacity: 1;
}

.labelstyle {
  height: 12rem;
  border: 0.3rem solid rgba(135, 146, 164, 0);
  background-color: #fff;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  border-radius: 0.5rem;
  padding: 3rem 3rem 2.5rem;
  cursor: pointer;
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  color: #323842;
  font-weight: 600;
}

.mil-custom-checkbox input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.mil-custom-checkbox .mil-checkbox-checkmark:after {
  content: "";
  position: absolute;
  margin-top: -0.3rem;
  width: 0.6rem;
  height: 1rem;
  border: solid #fff;
  border-width: 0 0.2rem 0.2rem 0;
  transform: rotate(45deg) scale(0);
  transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
}

.span1 {
  padding-right: 6rem;
  font-size: 1.8rem;
}

.imagedroit {
  width: 35px;
  margin-right: 20px;
}

.imageServiceCall {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

@media (max-width: 1024px) {

  /* Tes styles pour les écrans en dessous de 768px */
  .imageAdministration {
    width: 330px;
    height: 400px;
    object-fit: cover;
  }

  .packresponsive {
    font-size: 17px !important;
  }

  .packresponsive-1 {
    width: 286px !important;
  }

  .packstyle {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin: 7px !important;
  }

  .divpack2 {
    margin-bottom: 27px !important;
  }
}

@media (max-width: 1024px) {
  .hidden {
    display: none !important;
  }

  .widthhidden {
    width: 99% !important;
  }

}

.marginbottom104 {
  margin-bottom: -104px !important;
}

@media (max-width > 700px) {
  .order1700 {
    display: none !important;
    visibility: hidden !important;
  }
}
@media (min-width: 480px) {
  .nonedispaly {
   display: none !important;
   
  }
}
@media only screen and (max-width: 991px) {
  .navbar-toggler span:nth-child(1) {
      transform: rotate(50deg);
      transform-origin: 0% 80%;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-collapse a {
      padding: 15px 0px !important;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-toggler span:nth-child(3) {
      transform: rotate(-53deg);
      transform-origin: 15% 100%;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-nav .active > a {
      color:black !important;
  }
}
@media only screen and (max-width: 480px) {

  /* Styles pour écrans de 480px ou moins */
  header {
    padding: 0 !important;
  }

  .navbar .headerwitdh{
    display: flex;
  }

  .navbar-toggler {
    width: 48px !important;
  }

  .main-header .navbar-brand img {
    width: 130px !important;
  }

  .main-header {
    margin: 0 !important;
    padding: 0 !important;
  }

  .navbar-expand-lg {
    height: 82px !important;
  }


  .navbar-collapse {
    background-color: #e7e7e7 !important;
    width: 160px !important;
    margin-top: 10px;
    margin-right: -13px;
  }

  button:hover,
  .service-card a:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .imageProfil3 {
    height: 40px;
    width: 40px;
    margin-bottom: 10px;
    border-radius: 50%;
  }
  .displaynone1{
    display: none;
  }


}

/* Pour les téléphones avec une largeur maximale de 600px */
@media (max-width: 700px) {
  .nav-linkblack {
    display: none !important;
  }

  .width400 {
    width: 370px !important;
  }

  .margin0 {
    margin: 0 !important;
  }

  .flexwrap {
    flex-wrap: wrap !important;
  }

  .width300M {
    width: 300px !important;
  }

  .marginTop10 {
    margin-top: 10px !important;
    margin-left: 0 !important;
  }
}

@media (max-width: 1000px) {
  .dflexcolum {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width:820px) {
  .marginBottom220 {
    margin-bottom: 230px !important;
  }

  .menudropdown {
    display: block !important;
  }

  .d-flex-div {
    display: flex;
    justify-content: center;
  }

  .width400 {
    width: 500px;
  }

  .marginleftright0 {
    margin-left: 0 ! important;
    margin-right: 0 !important;
  }

  .displaynone {
    display: none;
    visibility: hidden;
    width: 0px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .marginleft0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .flexcolumn {
    flex-direction: column;
  }

  .with1001 {
    width: 100% !important;
  }

}

.height70 {
  height: 70px !important;
}


@media (min-width: 769px) and (max-width: 1023px) {
  .widthrespon{
    width: 700px !important;
    min-width: 700px !important;

  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .widthrespon{
    min-width: 300px !important; }
  .fontSize{
    font-size: 12px !important;
  }
 .fontSize2{
  font-size: 25px !important;
 }
 .width346M{
  width: 346px !important;
 }
 .width220{
  width: 220px !important;
 }
 .width0{
  width: 0px !important;
 }
}



/* splashscreem */
.splashscreen-sp {
  /* background-color: #faf6eb; */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  width: 100vw;  
  box-sizing: border-box;
  overflow: hidden;
}

.container-sp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px; 
  width: 100%;
  text-align: center;
}

.imageWrapper-sp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin-bottom: 40px;
}

.primaryImage-sp {
  object-fit: contain;
  object-position: center;
  width: 130%;
}

.secondaryImage-sp {
  aspect-ratio: 1.61;
  object-fit: contain;
  object-position: center;
  width: 250px;
  max-width: 100%;
  margin-top: 10px;
}

.loadingDots-sp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.loadingDots-sp span {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #333;
  border-radius: 50%;
  animation: loadingAnimation 1.4s infinite ease-in-out;
}

.loadingDots-sp span:nth-child(1) {
  animation-delay: 0s;
}
.loadingDots-sp span:nth-child(2) {
  animation-delay: 0.2s;
}
.loadingDots-sp span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingAnimation {
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0.3;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
}


.loading-container-sp {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.dot-sp {
  width: 40px;
  height: 40px;
  background-color: #4caf50;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot-sp:nth-child(2) {
  animation-delay: 0.2s;
}

.dot-sp:nth-child(3) {
  animation-delay: 0.4s;
}

.dot-sp:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes pulse {
  0%, 100% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(0.9);
      opacity: 0.7;
  }
}


@media (min-width: 320px) and (max-width: 600px) {

.primaryImage-sp {
  object-fit: contain;
  object-position: center;
  width: 60% ;
}
.loading-container-sp {
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 50px;
}


}