body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

header {
    background-color: #4CAF50;
    color: white;
    padding: 1em 0;
    text-align: center;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    color: white;
    text-decoration: none;
}

main {
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
}

h2 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5em;
    font-weight: bold;
    color: #4CAF50;
}

.content {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-between;
}

.partner-details {
    flex: 1;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.partner-details:hover {
    transform: scale(1.02);
}

.partner-details h3 {
    font-size: 1.75em;
    color: #4CAF50;
}

.partner-details p {
    font-size: 1.1em;
    margin: 10px 0;
    line-height: 1.6;
}

.partner-details button {
    display: inline-block;
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s, transform 0.3s;
}

.partner-details button:hover {
    background-color: #218838;
    transform: scale(1.05);
}

.partner-image {
    flex: 1;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s;
}

.partner-image:hover {
    transform: scale(1.02);
}

.partner-image img {
    width: 80%;
    height: auto;
    border-radius: 8px;
}

.contact-form {
    margin-top: 40px;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
}

.contact-form:hover {
    transform: scale(1.02);
}

.contact-form h3 {
    font-size: 1.75em;
    color: #007bff;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

label {
    font-weight: bold;
    color: #343a40;
}

input, textarea {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1em;
    color: #495057;
    background-color: #fff;
    transition: border-color 0.3s;
}

input:focus, textarea:focus {
    border-color: #007bff;
    outline: none;
}

footer {
    background-color: #343a40;
    color: white;
    text-align: center;
    padding: 20px 0;
    position: relative;
    width: 100%;
    bottom: 0;
}

footer ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin: 0;
}

footer ul li {
    margin: 0 15px;
}

footer ul li a {
    color: white;
    text-decoration: none;
    transition: color 0.3s;
}

footer ul li a:hover {
    color: #ffcc00;
}