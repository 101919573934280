.mil-top-panel .container {
    height: 100%;
  }
  .mil-p-0-f {
    padding-bottom: 10rem;
  }
/* -------------------------------------------

calculator

------------------------------------------- */
.mil-calculator .mil-custom-select {
    width: 100%;
    height: 9rem;
    position: relative;
    z-index: 2;
  }
  .mil-calculator .mil-custom-select i {
    height: 100%;
  }
  .mil-calculator .mil-custom-select .mil-select-button {
    border: none;
    height: 9rem;
  }
  .mil-calculator .mil-custom-select .mil-select-button .mil-selected-value {
    color: #323842;
    opacity: 1;
  }
  .mil-calculator .mil-custom-select .mil-select-dropdown {
    top: 10rem;
    z-index: 9;
  }
  .mil-calculator .mil-calc-panel {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 9rem;
    background-color: #fff;
    padding: 3rem;
    padding-bottom: 0;
  }
  .mil-calculator .mil-calc-panel-2 {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 9rem;
    background-color: #fff;
    padding: 1.5rem 3rem;
    padding-bottom: 0;
  }
  .mil-calculator .mil-service-item {
    display: block;
    background-color: #fff;
    height: 9rem;
  }
  .mil-calculator .mil-total-price {
    padding: 3rem;
    padding-bottom: 0;
  }
  .mil-calculator .mil-total-price .mil-tp-frame {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    border-radius: 9rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mil-calculator .mil-total-price .mil-tp-frame .mil-price-number {
    min-width: 28rem;
  }
  .mil-calculator .mil-total-price .mil-tp-frame button {
    border: none;
    cursor: pointer;
  }
  @media screen and (max-width: 390px) {
    .mil-calculator .mil-total-price .mil-tp-frame {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
    }
    .mil-calculator .mil-total-price .mil-tp-frame .mil-price-number {
      margin-bottom: 3rem;
    }
  }
  .mil-aic {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mil-jcb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .mil-mb-15 {
    margin-bottom: 1.5rem;
  }
  .mil-sm-mb-15 {
    margin-bottom: 1.5rem;
  }
  

.mil-input-frame {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .mil-input-frame input, .mil-input-frame .mil-select-button {
    width: 100%;
    height: 7rem;
    padding-left: 3rem;
    font-size: 1.6rem;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-family: "Outfit", sans-serif;
    border: solid 0.1rem rgba(135, 146, 164, 0.3);
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame input .mil-selected-value, .mil-input-frame .mil-select-button .mil-selected-value {
    opacity: 0.6;
  }
  .mil-input-frame input .mil-selected-value.mil-selected, .mil-input-frame .mil-select-button .mil-selected-value.mil-selected {
    opacity: 1;
  }
  .mil-input-frame input::-webkit-input-placeholder, .mil-input-frame .mil-select-button::-webkit-input-placeholder {
    color: #8792a4;
  }
  .mil-input-frame input::-moz-placeholder, .mil-input-frame .mil-select-button::-moz-placeholder {
    color: #8792a4;
  }
  .mil-input-frame input:-ms-input-placeholder, .mil-input-frame .mil-select-button:-ms-input-placeholder {
    color: #8792a4;
  }.mil-input-frame
  .mil-input-frame input::-ms-input-placeholder, .mil-input-frame .mil-select-button::-ms-input-placeholder {
    color: #8792a4;
  }
  .mil-input-frame input::placeholder, .mil-input-frame .mil-select-button::placeholder {
    color: #8792a4;
  }
  .mil-input-frame input:hover, .mil-input-frame input:focus, .mil-input-frame .mil-select-button:hover, .mil-input-frame .mil-select-button:focus {
    outline: inherit;
  }
  .mil-input-frame input:focus + i, .mil-input-frame .mil-select-button:focus + i {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    opacity: 1;
  }
  .mil-input-frame textarea {
    height: 22rem;
    width: 100%;
    padding: 2.5rem 3rem;
    font-size: 1.6rem;
    font-family: "Outfit", sans-serif;
    border: solid 0.1rem rgba(135, 146, 164, 0.2);
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame textarea::-webkit-input-placeholder {
    color: #8792a4;
  }
  .mil-input-frame textarea::-moz-placeholder {
    color: #8792a4;
  }
  .mil-input-frame textarea:-ms-input-placeholder {
    color: #8792a4;
  }
  .mil-input-frame textarea::-ms-input-placeholder {
    color: #8792a4;
  }
  .mil-input-frame textarea::placeholder {
    color: #8792a4;
  }
  .mil-input-frame textarea:hover, .mil-input-frame textarea:focus {
    outline: inherit;
  }
  .mil-input-frame textarea:focus + i {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    opacity: 1;
  }
  .mil-input-frame.mil-custom-select i {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame i {
    opacity: 0.2;
    border-radius: 50%;
    position: absolute;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    right: 0.1rem;
    top: 0.1rem;
    background-color: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 6.8rem;
    width: 6.8rem;
    pointer-events: none;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame .mil-select-dropdown {
    position: absolute;
    top: 8.5rem;
    z-index: 999;
    list-style: none;
    width: 100%;
    background-color: #5A8CF5;
    padding: 1.5rem;
    -webkit-transform: translateY(-0.7rem) scale(0.9);
            transform: translateY(-0.7rem) scale(0.9);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame .mil-select-dropdown li {
    position: relative;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 0.5rem;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame .mil-select-dropdown li label {
    line-height: 100%;
    color: #fff;
    width: 100%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 0.5rem;
    padding: 1.5rem 1.5rem;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-input-frame .mil-select-dropdown li label:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .mil-input-frame .mil-select-dropdown li:hover, .mil-input-frame .mil-select-dropdown li input:checked ~ label {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .mil-input-frame .mil-select-dropdown li input:focus ~ label {
    background-color: #8792a4;
  }
  .mil-input-frame .mil-select-dropdown li input[type=radio] {
    position: absolute;
    left: 0;
    opacity: 0;
  }
  .mil-input-frame.mil-active i {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    opacity: 1;
  }
  .mil-input-frame.mil-active .mil-select-dropdown {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0) scale(1);
            transform: translateY(0) scale(1);
  }
  .mil-input-frame:hover i, .mil-input-frame:focus i {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
    opacity: 1;
  }  

  .mil-input-frame.mil-custom-select i {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-calculator .mil-custom-select {
    width: 100%;
    height: 9rem;
    position: relative;
    z-index: 2;
  }
  .mil-calculator .mil-custom-select i {
    height: 100%;
  }
  .mil-calculator .mil-custom-select .mil-select-button {
    border: none;
    height: 9rem;
  }
  .mil-calculator .mil-custom-select .mil-select-button .mil-selected-value {
    color: #323842;
    opacity: 1;
  }
  .mil-calculator .mil-custom-select .mil-select-dropdown {
    top: 10rem;
    z-index: 9;
  }
  .mil-up {
    will-change: transform;
  }
  .testrow{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-around ;

  }

  @media (max-width: 768px) {
   .widthService{
    width: 325px !important;}
    .positionDescription{
      top: 100px !important;
      left:166px !important;
      width: 300px !important;
      max-height: 350px !important;
    }
   }
    
  @media (max-width: 1280px) {
      .marginbottomService{
      margin-bottom:-550px !important;
      }
      .marginbottomService2{
        margin-bottom:-250px !important;
        }
      .hiddenimage{
        display: none;
      }
      .marginbottom130{
        margin-bottom: -250px !important;
      }
      /* .width400{
        width: 600px !important;
      } */
       }
  
  .mil-up {
    opacity: 0;
  }
  .mil-discount-card .mil-dscnt-part .mil-up-text {
    padding-top: 4rem;
    height: 20rem;
    width: 20rem;
    position: absolute;
    z-index: 1;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .mil-up-text {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
  }
  .mil-br-md {
    border-radius: 1rem;
  }
  .mil-hero-form-frame.mil-bg-m-4 {
    background-color: rgba(255, 255, 255, 0.9);
    border: solid 0.1rem white;
    -webkit-backdrop-filter: blur(5rem);
            backdrop-filter: blur(5rem);
  }
  .mil-bg-m-4 {
    background-color: #fff !important;
  }
  .mil-fs-18 {
    font-size: 14px !important;
    margin-bottom: 10px;
    /* white-space: nowrap !important; */
    
  }
  .mil-fw-600 {
    font-weight: 600;
  }
  .mil-m-1 {
    color: #323842;
  }
  .mil-text-link.mil-a-2:after {
    background-color: #5A8CF5;
  }
  /* -------------------------------------------

services

------------------------------------------- */
.mil-service-card {
    padding: 4rem;
    display: block;
    -webkit-transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.4s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-service-card .mil-card-icon {
    width: 60px;
  }
  .mil-service-card:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
  .mil-service-card:hover .mil-btn  {
    background-color: #EDCE29 !important;
  }
  .bdf{
    background-color: #EDCE29 !important;
  }
  
  .mil-service-card-long .mil-img-frame {
    width: 100%;
    height: 100%;
    min-height: 30rem;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
  }
  .mil-service-card-long .mil-img-frame img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  .mil-service-card-long .mil-inner-frame {
    padding: 6rem;
    height: 100%;
  }
  /* -------------------------------------------

checkbox

------------------------------------------- */
.mil-custom-checkbox {
    height: 5rem;
    border: 2px solid rgba(135, 146, 164, 0);
    background-color: #fff;
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            /* align-items: flex-end; */
    border-radius: 0.5rem;
    padding: 3rem 1.2rem;
    cursor: pointer;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-custom-checkbox.mil-checked {
    border-color: #EDCE29 !important;
  }
  .mil-custom-checkbox.mil-checked.mil-already-included {
    pointer-events: none;
  }
  .mil-custom-checkbox.mil-checked.mil-already-included:after {
    content: "Déjà inclus";
    position: absolute;
    top: 0;
    left: 2rem;
    font-weight: 400;
    font-size: 10px;
    background-color: #EDCE29;
    padding: 0.3rem 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-custom-checkbox.mil-unavailable {
    opacity: 0.4;
    pointer-events: none;
    border: none;
  }
  .mil-custom-checkbox.mil-unavailable:after {
    content: "Temporarily unavailable";
    position: absolute;
    top: 0;
    left: 3rem;
    font-weight: 400;
    font-size: 12px;
    background-color: #f3f4f6;
    padding: 0.3rem 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-custom-checkbox .mil-checkbox-checkmark {
    position: absolute;
    bottom: 1.6rem;
    right: 2.4rem;
    height: 22px;
    width: 22px;
    background-color: #f3f4f6;
    border: 0.1rem solid rgba(135, 146, 164, 0.4);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-custom-checkbox input[type=checkbox]:checked ~ .mil-checkbox-checkmark {
    background-color: #5A8CF5;
    border-color: #5A8CF5;
  }
  .mil-custom-checkbox .mil-checkbox-checkmark:after {
    content: "";
    position: absolute;
    margin-top: -0.3rem;
    width: .3rem;
    height: 0.6rem;
    border: solid #fff;
    border-width: 0 .1rem 0.1rem 0;
    -webkit-transform: rotate(45deg) scale(0);
            transform: rotate(45deg) scale(0);
    -webkit-transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
    transition: 0.2s cubic-bezier(0, 0, 0.3642, 1);
  }
  .mil-custom-checkbox input[type=checkbox]:checked ~ .mil-checkbox-checkmark:after {
    -webkit-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
  }
  .mil-custom-checkbox .mil-service-name {
    padding-right: 6rem;
  }
  .mil-custom-checkbox input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .mil-custom-checkbox:hover {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
  }
  .mil-custom-checkbox:hover .mil-checkbox-checkmark {
    border: 0.2rem solid #5A8CF5;
  }

  /* .htmltemplate {
    font-size: 9px;
  }
  @media (max-width: 1800px) {
    html {
      font-size: 8px;
    }
  }
  @media (max-width: 1200px) {
    html {
      font-size: 7px;
    }
  }
  @media (max-width: 992px) {
    html {
      font-size: 6px;
    }
  } */
.mil-fs-36 {
    font-size: 3.6rem;
}
.mil-btn.mil-icon-btn {
  padding: 0 1rem 0 4rem !important;
}

.mil-btn {
  border: none  !important;
  text-transform: uppercase  !important;
  letter-spacing: 0.2rem  !important;
  font-weight: 500  !important;
  /* font-family: "Outfit", sans-serif  !important; */
  font-size: 1.4rem  !important;
  white-space: nowrap  !important;
  color: #323842  !important;
  padding: 0 4rem  !important;
  display: -webkit-inline-box  !important;
  display: -ms-inline-flexbox  !important;
  display: inline-flex  !important
;
  -webkit-box-align: center  !important;
  -ms-flex-align: center  !important;
  align-items: center  !important;
  height: 7rem  !important;
  padding: 0 4rem  !important;
  -webkit-transition: 0.4scubic-bezier(0, 0, 0.3642, 1) !important;
  transition: 0.4scubic-bezier(0, 0, 0.3642, 1)  !important;
}
.mil-br-xl {
  border-radius: 10rem  !important;
}
.mil-bg-a-2 {
  background-color: #5A8CF5 !important;
}
@media (width< 680px) {

  .widhheight{
    width: 335px !important;
    height: 580px !important;
    border-radius: 10px !important;

  }
  .fontSizetext{
    font-size: 17px !important;
  }
  .hiddenbutton{
    visibility: hidden;
  }
  .buttoyellowWdth{
    width: 110px;
  }
  .marginright38{
    margin-right: 38px;
  }
  .marginleft28{
    margin-left: 38px;
  }
  .flexwrap1{
    flex-wrap: wrap;
  }
  .widthprofilbnef{
    width: 332px !important;
  }
  .withtext{
    font-size: 16px !important;
    margin-top: 20px;
  }
  .with300px{
    width: 300px !important;
  }
}
.marginbottom130{
  margin-bottom: -130px;
}
.dflexresposive{
  justify-content: center !important;
  flex-wrap: wrap !important;
  align-items: center !important;
}