body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

header {
    background-color: #4CAF50;
    color: white;
    padding: 1em 0;
    text-align: center;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    color: white;
    text-decoration: none;
}

main {
    padding: 20px;
    max-width: 1400px;
}

.service-cards, .secondary-service-cards, .partner-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.service-card, .secondary-service-card, .partner-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 200px;
}

button, .service-card a, .secondary-service-card a, .partner-card a {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

button:hover, .service-card a:hover, .secondary-service-card a:hover, .partner-card a:hover {
    background-color: #45a049;
}

footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer ul li {
    display: inline;
    margin: 0 10px;
}

footer ul li a {
    color: white;
    text-decoration: none;
}

form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

label {
    font-weight: bold;
}

input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    align-self: flex-start;
}


body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

header {
    background-color: #4CAF50;
    color: white;
    padding: 1em 0;
    text-align: center;
}

nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    color: white;
    text-decoration: none;
}

main {
    padding: 20px;
}

.hero {
    text-align: center;
    margin-bottom: 40px;
}

.service-cards {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.service-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 300px;
}

.service-card img {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
}

button, .service-card a {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

button:hover, .service-card a:hover {
    background-color: #45a049;
}

footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

footer ul {
    list-style: none;
    padding: 0;
}

footer ul li {
    display: inline;
    margin: 0 10px;
}

footer ul li a {
    color: white;
    text-decoration: none;
}

form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

label {
    font-weight: bold;
}

input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    align-self: flex-start;
}

.listePartenaires body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
    color: #333;
}

.listePartenaires header {
    background-color: #4CAF50;
    color: white;
    padding: 1em 0;
    text-align: center;
}

.listePartenaires nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
}

.listePartenaires nav ul li {
    margin: 0 15px;
}

.listePartenaires nav ul li a {
    color: white;
    text-decoration: none;
}

.listePartenaires main {
    padding: 20px;
}

.listePartenaires .hero {
    text-align: center;
    margin-bottom: 40px;
}

.listePartenaires .prescription-upload, .pharmacy-responses, .partners {
    margin-top: 20px;
}

.listePartenaires form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.listePartenaires label {
    font-weight: bold;
}

.listePartenaires input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.listePartenaires button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.listePartenaires button:hover {
    background-color: #45a049;
}

.listePartenaires .partner-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
}

.listePartenaires .partner-card {
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    width: 200px;
}

.listePartenaires .pharmacy-responses img {
    display: block;
    margin: 0 auto 10px auto;
    width: 100%;
    max-width: 200px;
    height: auto;
}
