@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.sectiondiv {
  position: relative;
  max-height: 100vh;
  width: 100%;
  background: #121321;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  perspective: 1000px;
  margin-top: 74px;
}
.sectiondiv::before {
  content: "";
  position: absolute;
  height: 240px;
  width: 240px;
  border-radius: 50%;
  transform: translate(-150px, -100px);
  background: linear-gradient(90deg, #9c27b0, #f3f5f5);
}
.sectiondiv::after {
  content: "";
  position: absolute;
  height: 240px;
  width: 240px;
  border-radius: 50%;
  transform: translate(150px, 100px);
  background: linear-gradient(90deg, #9c27b0, #f3f5f5);
}
.containerCredit {
  position: relative;
  height: 225px;
  width: 375px;
  z-index: 100;
  transition: 0.6s;
  transform-style: preserve-3d;
}
.containerCredit:hover {
  transform: rotateY(180deg);
}
.containerCredit .card {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 25px;
  border-radius: 25px;
  backdrop-filter: blur(25px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backface-visibility: hidden; /* Ensure backface-visibility is set */
  transform-style: preserve-3d;
}
.frontface {
  backface-visibility: hidden;
  background: transparent;
}
.frontface header,
.frontface .logo {
  display: flex;
  align-items: center;
}
.frontface header {
  justify-content: space-between;
}
.frontface .logo img {
  width: 48px;
  margin-right: 10px;
}
.titrecredit {
  font-size: 16px;
  font-weight: 400;
}
.frontface .chip {
  width: 50px;
}
.frontface .carddetails {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.CardNumber {
  font-size: 10px;
  font-weight: 400;
}
.titrecredit.number {
  font-size: 18px;
  letter-spacing: 1px;
}
.titrecredit.name {
  margin-top: 20px;
}
.card.backface {
  border: none;
  padding: 15px 25px 25px 25px;
  transform: rotateY(180deg);
  backface-visibility: hidden; /* Ensure backface-visibility is set */
}
.card.backface .CardNumber {
  font-size: 8px;
}
.card.backface .magnetic-strip {
  position: absolute;
  top: 40px;
  left: 0;
  height: 45px;
  width: 100%;
  background: #000;
}
.card.backface .signature {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 80px;
  height: 40px;
  width: 85%;
  border-radius: 6px;
  background: repeating-linear-gradient(#fff,
      #fff 3px,
      #efefef 0px,
      #efefef 9px);
}
.signature i {
  color: #000;
  font-size: 12px;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: #fff;
  margin-right: -30px;
  z-index: -1;
}
.card.backface .titrecredit {
  font-size: 8px;
  margin-top: 15px;
}